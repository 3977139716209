import React, { useState, useRef, useEffect, Component } from "react";
import Slider from "react-slick";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

function ModalWidht() {
  if (window.screen.width > 800) {
    return '40vw';
  } else {
    return '80vw';
  }
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

function Esalenlong () {
  return (
    <p>Vor über 50 Jahren entstand die Esalen®- Massage und wird am Esalen Institut in Kalifornien praktiziert. Die Kombination verschiedenster Körpertherapien wie Akupressur, Craniosacral, Feldenkrais, Polarity, Rolfing, Shiatsu u.a. charakterisieren die Esalen®-Massage.<br /><br />
Die Esalen®-Massage ist als <b className=" font-bold">Kunst der bewussten Berührung</b>  bekannt.<br /><br />
Anmutige und kraftvolle Formen des Berührens durch lange fliessende Streichungen über die Gesamtlänge des Körpers, passive Gelenkübungen, sowie sanft wiegende Bewegungen, stets im Einklang mit der Atmung, lassen dich und den Practitioner eine meditative Atmosphäre erleben.<br /><br />
Als Esalen® Practitioner widme ich mich dem Bedürfnis deiner physischen, mentalen, energetischen und emotionalen Ganzheit. Dadurch ist jede Esalen®-Massage individuell und verschieden in der Anwendung.<br /><br />
Du erfährst einen Zustand tiefer Entspannung. Die Sinneswahrnehmung und das Körperbewusstsein werden erhöht und steigern die Einheit von Körper, Geist und Seele. Es bietet sich Raum, physische und psychische Themen umzuwandeln. <br /><br />
Diese Form der Berührung ermöglicht dir, dich als einmalig und ganzheitlich wahrzunehmen.</p>
  );
}

function Heilarbeitshort () {
  return (
    <p>
      <b className=" font-bold">EPI®</b> (<b className=" font-bold">E</b>xtraktion <b className=" font-bold">P</b>athologischer <b className=" font-bold">I</b>nformationen) ist eine Heilmethode. <b className=" font-bold">EPI®</b>
    ergänzt und unterstützt Schulmedizin, Naturheilkunde und andere
    therapeutische Methoden.<br /><br />
    
    </p>
  );
}

function Metalong () {
  return (
    <p>Metamorphose, auch Pränataltherapie genannt, wurde 1955 vom englischen Therapeuten Robert St. John begründet. Die “Pränataltherapie“ ist eine Technik und Arbeitsmethode, um den Zugang zur vorgeburtlichen Phase des Lebens zu erlangen und somit die Selbsthilfe und die persönliche Entwicklung zu fördern.<br /><br />
In den Monaten vom Zeitpunkt der Empfängnis bis zur Geburt wird unser Körper gebildet und ebenso energetische Muster, die später grossen Einfluss auf unsere Entwicklung haben.<br /><br />
Bilden sich Stress-Muster, können sich diese in Form von Blockaden, Ängsten und gesundheitlichen Störungen zeigen.<br /><br />
Die Metamorphose orientiert sich, ähnlich wie die Fussreflexzonenmassage, an den Reflex-Punkten der Füsse, den Händen und dem Kopf. Jedoch werden dort nur die Reflexzonen der Wirbelsäule behandelt. Die Wirbelsäule ist die Hauptknochenstütze unseres Körpers und ist mit dem zentralen Nervensystem verbunden. Es werden alle Aspekte unserer Persönlichkeit in der Wirbelsäule gespeichert und sind dadurch abrufbar.<br /><br />
Durch die gezielte Behandlung des Reflexbereiches deiner Wirbelsäule an deinen Füssen, Händen und deinem Kopf können auf eine ganz natürliche Weise vorgeburtliche verfestigte Muster aufgebrochen und die ursprünglichen Kräfte deiner Psyche freigesetzt werden. <br /><br />
Die Metamorphose ist eine sanfte Massage mit kreisenden und streichenden Bewegungen, die dir ermöglicht dein <b className=" font-bold">Dasein</b> ins Gleichgewicht zu bringen.</p>
  );
}

function Heilarbeitlong () {
  return (
    <p> <b className=" font-bold">EPI®</b> (<b className=" font-bold">E</b>xtraktion <b className=" font-bold">P</b>athologischer <b className=" font-bold">I</b>nformationen) ist eine Heilmethode. <b className=" font-bold">EPI®</b>
    ergänzt und unterstützt Schulmedizin, Naturheilkunde und andere
    therapeutische Methoden.<br /><br />
    Entwickelt wurde <b className=" font-bold">EPI®</b> von <b className=" font-bold">Hannes Jacob</b>, Therapeut, Heiler, Buchautor. In
    regelmässiger Zusammenarbeit mit Ärzten behandeln Hannes Jacob und
    andere EPI-Therapeuten mit dieser Methode erfolgreich viele Menschen.
    <br /><br />

    Mit der Methode <b className=" font-bold">EPI®</b> werden pathologische Informationen im Körper
    gelöscht. Ziel der Therapie ist die Behebung der Ursachen der Beschwerden.

    In der Regel stellt sich nach der ersten Behandlung eine Heilung oder
    Linderung der Beschwerden ein.<br /><br />
    Die Behandlung eignet sich für Menschen jeden Alters.<br /><br />
    <b className=" font-bold">EPI®</b> ist nicht auf bestimmte medizinische Zustände oder Krankheiten
    beschränkt. Eingesetzt werden kann <b className=" font-bold">EPI®</b> sowohl bei chronischen wie auch
    akuten Beschwerden.<br /><br />

    <div className=" w-full text-left ">
    <b className=" font-bold">Häufig erfolgreich behandelt werden:</b> <br />
    <ul className="list-disc ml-4">
      <li>Verschiedene rheumatische Beschwerden</li>
      <li>Nahrungsmittelunverträglichkeiten</li>
      <li>Allergien: Pollenallergie, Nahrungsmittel, Hausstaubmilben, Tierhaare</li>
      <li>Asthma</li>
      <li>Hauterkrankungen</li>
      <li>Multiple Sklerose</li>
      <li>Entzündungen (z.B. Blasenentzündung)</li>
      <li>Schmerzlinderung (generalisierte und chronischen Schmerzen)</li>
      <li>Nebenwirkung von Chemotherapie</li>
      <li>Postoperative Beschwerden</li>
      <li>Migräne</li>
      <li>Menstruationsbeschwerden</li>
    </ul><br />
    </div>
    <b className=" font-bold">Behandlung</b><br /><br />
    Die Behandlung besteht in der Regel aus zwei Sitzungen.<br/>
    Erstsitzung inklusive Anamnese ca. 60 min.<br/>
    Zweitsitzung ca. 35 min. <br/>
    Für den Klient, der auf dem Rücken oder Bauch liegt, besteht die Behandlung aus einer Massage mehrerer Punkte am Körper.
    <br /><br />

Link <a className=" text-[#236951] underline" href="https://www.hannesjacob.ch/de/">www.hannesjacob.ch</a> <br /><br />
     
    </p>
  );
}






function Price() {
  const [showMore, setShowMore] = useState(false);
  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);
  const text = {textone : 'Heilarbeit ist ein Angebot, das neben der Schulmedizin, der Naturheilkunde und weiteren therapeutischen Methoden als Ergänzung besteht. ',
                texttwo : 'Metamorphose, auch Pränataltherapie genannt, wurde 1955 vom englischen Therapeuten Robert St. John begründet. Die “Pränataltherapie“ ist eine Technik und Arbeitsmethode, um den Zugang zur vorgeburtlichen Phase des Lebens zu erlangen und somit die Selbsthilfe und die persönliche Entwicklung zu fördern. In den Monaten, vom Zeitpunkt der Empfängnis bis zur Geburt, wird unser Körper gebildet und ebenso energetische Muster, die später grossen Einfluss auf unsere Entwicklung haben. Bilden sich Stress-Muster, können sich diese in Form von Blockaden, Ängsten und gesundheitlichen Störungen zeigen. Die Metamorphose orientiert sich, ähnlich wie die Fussreflexzonenmassage, an den Reflex-Punkten der Füsse, den Händen und dem Kopf. Jedoch werden dort nur die Reflexzonen der Wirbelsäule behandelt. Die Wirbelsäule ist die Hauptknochenstütze unseres Körpers und ist mit dem zentralen Nervensystem verbunden. Es werden alle Aspekte unserer Persönlichkeit in der Wirbelsäule gespeichert und sind dadurch abrufbar. Durch die gezielte Behandlung des Reflexbereiches der Wirbelsäule an den Füssen, den Händen und dem Kopf können auf eine ganz natürliche Weise vorgeburtliche verfestigte Muster aufgebrochen und die ursprünglichen Kräfte der Psyche freigesetzt werden. Die Metamorphose ist eine sanfte Massage mit kreisenden und streichenden Bewegungen, die dem Mensch ermöglicht sein Dasein ins Gleichgewicht zu bringen.',
                textthree: 'Vor über 50 Jahren entstand die Esalen®- Massage und wird am Esalen Institut in Kalifornien praktiziert. Die Kombination verschiedenster Körpertherapien wie Akupressur, Craniosacral, Feldenkrais, Polarity, Rolfing, Shiatsu u.a. charakterisieren die Esalen®-Massage. '
};

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  

  return (
    <div className=" w-[100vw]  bg-white flex justify-center flex-col " >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Box sx={style} className=" w-[80vw] md:w-[40vw]">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Wie viel kostet die Esalen®-Massage?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Die Esalen®-Massage kostet <b>160.- CHF</b>. Je nach Klient kann die Behandlungsdauer unterschiedlich sein.
            Jede weitere Viertelstunde wird mit CHF 30.- berechnet
            <br/><br/>

            <a onClick={() => {
              handleClose();
              document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });}}
              className="underline text-[#236951] cursor-pointer"> Kontaktiere</a> mich gerne bei Fragen.
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className=" w-[80vw] md:w-[40vw]">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Wie viel kostet die Metamorphose?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Die Metamorphose kostet <b>120.- CHF</b>. Je nach Klient kann die Behandlungsdauer unterschiedlich sein.
          Jede weitere Viertelstunde wird mit CHF 30.- berechnet.
          <br/><br/>
            <a onClick={() => {
              handleClose1();
              document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });}}
              className="underline text-[#236951] cursor-pointer"> Kontaktiere</a> mich gerne bei Fragen.
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className=" w-[80vw] md:w-[40vw]">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Wie viel kostet die <b className=" font-bold">EPI®</b>?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          1. Behandlung: CHF 150.- <br/>
          2. Behandlung: CHF 70.- <br/><br/>
          Zusätzliche Behandlungen für die gleiche Pathologie innerhalb der nächsten 6 Monate kosten CHF 70.-.<br/><br/>
          Je nach Klient und dessen Bedürfnisse kann die Behandlungsdauer unterschiedlich sein.<br/>
          Jede weitere Viertelstunde wird mit CHF 30.- berechnet.<br/><br/>
          Kinder unter 14 Jahren und Kleinkinder: CHF 50.-.<br/><br/>
            <a onClick={() => {
              handleClose2();
              document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });}}
              className="underline text-[#236951] cursor-pointer"> Kontaktiere</a> mich gerne bei Fragen.
          </Typography>
        </Box>
      </Modal>


      <div className="text-5xl md:text-7xl font-semibold flex justify-center items-center flex-col md:flex-row " id="angebote">
            <div>Mein</div> 
            <h1 className="text-[#236951] ml-4"> Angebot</h1>
            
      </div>
        
        <div className=" w-full flex justify-center items-center">
          <div className=" w-16 h-1 bg-black my-10"></div>
        </div>
        
      <div className=" w-full  flex justify-evenly lg:flex-nowrap md:flex-wrap flex-col md:flex-row mt-16 mb-8 ">



        <div className=" lg:w-[27vw] w-[80vw] md:w-88  m-10 lg:m-0 shadow-2xl rounded-lg max-w-md fit">
          <div className=" flex justify-center items-center flex-col mb-10 ">
            <div className="text-3xl md:text-4xl font-bold mt-4 mb-2 flex flex-wrap">
              <h1 className=" ">EPI®</h1><p className="text-[#236951] "></p>
            </div>
            
            <h3 className=" text-lg font-medium text-center px-4">
              <b className=" font-bold">E</b>xtraktion <b className=" font-bold">P</b>athologischer <b className=" font-bold">I</b>nformationen
            </h3>
            <div className=" flex-row flex justify-center items-center h-10 mt-8">
            <i class="fa-regular fa-clock fa-xl mr-2"></i><p className=" text-3xl font-semibold">35-60min</p>
            </div>
            
            <button onClick={handleOpen2} className=" mb-9 text-lg text-[#236951] underline cursor-pointer">Preis</button>
            
            <p className=" text-base font-thin mx-6 text-center">
            {showMore ? <Heilarbeitlong/> : <Heilarbeitshort/>}
            </p>
            <button onClick={() => setShowMore(!showMore)}>
              {showMore ? "Zeige weniger" : "Zeige mehr"}
            </button>
          </div>  
        </div>


        <div className=" lg:w-[27vw] w-[80vw] md:w-88  m-10 lg:m-0 shadow-2xl rounded-lg max-w-md fit">
          <div className=" flex justify-center items-center flex-col mb-10 ">
            <div className="text-3xl md:text-4xl font-bold mt-4 mb-2 flex flex-wrap">
              <h1 className=" ">Metamorphose</h1>
            </div>
            
            <h3 className=" text-lg  font-medium">Die pränatale Therapie</h3>
            <div className=" flex-row flex justify-center items-center h-10 mt-8">
            <i class="fa-regular fa-clock fa-xl mr-2"></i><p className=" text-3xl font-semibold">60min</p>
            </div>
            
            <button onClick={handleOpen1} className=" mb-9 text-lg text-[#236951] underline cursor-pointer">Preis</button>
            
            <p className=" text-base font-thin mx-6 text-center">
            {showMore1 ? <Metalong/> : text.texttwo.substring(0, 301)}
            </p>
            <button onClick={() => setShowMore1(!showMore1)}>
              {showMore1 ? "Zeige weniger" : "Zeige mehr"}
            </button>
          </div>  
        </div>





        <div className=" lg:w-[27vw] w-[80vw] md:w-88  m-10 lg:m-0  shadow-2xl rounded-lg max-w-md fit">
          <div className=" flex justify-center items-center flex-col mb-10 ">
            <div className="text-3xl md:text-4xl font-bold mt-4 mb-2 flex flex-wrap">
              <h1 className=" ">Esalen®-Massage</h1><p className="text-[#236951] 2"></p>
            </div>
            
            <h3 className=" text-lg font-medium">Ganzheitliche Körpermassage</h3>
            <div className=" flex-row flex justify-center items-center h-10 mt-8">
            <i class="fa-regular fa-clock fa-xl mr-2"></i><p className=" text-3xl font-semibold">90min</p>
            </div>
            
            <button onClick={handleOpen} className=" mb-9 text-lg text-[#236951] underline cursor-pointer">Preis</button>
            
            <p className=" text-base font-thin mx-6 text-center">
            {showMore2 ? <Esalenlong/> : text.textthree.substring(0, 300)}
            </p>
            <button onClick={() => setShowMore2(!showMore2)}>
              {showMore2 ? "Zeige weniger" : "Zeige mehr"}
            </button>
          </div>  
        </div>

        

        

        


       
        

      </div>
          <p className=" self-start mb-16 mx-[5vw]  text-sm md:text-base">Als Zahlungsmethoden werden Bargeld & Twint akzeptiert.
          <br/>

Solltest du einen gebuchten Termin nicht wahrnehmen können, bitte ich um eine telefonische Absage bis spätestens 24 Stunden im Voraus. Ansonsten muss der Termin verrechnet werden.
<br/>
Danke für das Verständnis. </p>
    </div>
  );



}

export default Price;
