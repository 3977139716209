import React, { useState, useRef, useEffect } from "react";


function AboutMe() {
  
  

  return (
    <div className=" w-[100vw] md:py-8 bg-white md:flex justify-center items-center " id="about">
      <div className=" w-full md:w-[90%] lg:w-[80%] flex flex-col md:flex-row items-center">
        <div className=" h-[80vh] w-[80vw] md:w-[40%] my-[10vh] md:my-0 bgImage rounded md:self-start"></div>
        <div className=" md:ml-10 w-[80vw]  md:w-[50%] flex flex-col">
          <div className="text-5xl md:text-7xl font-semibold flex  ">
            <h1>Über </h1>
            <h1 className="text-[#236951] ml-4"> Mich</h1>
          </div>
          <div className="   w-16 h-1 bg-black my-9"></div>
          <p className=" mb-[10vh] text-sm font-normal">
          Das Zusammenspiel von Körper, Seele und Geist fasziniert mich immer wieder aufs Neue. Der Fluss des Lebens ist durch Energie gelenkt. Balance und Stabilität in Gleichklang zu bringen ist eine wundervolle Aufgabe. Das mache ich in meiner Arbeit erfahrbar.<br /><br />
Als bodenständiger Mensch war es eine Herausforderung und gleichzeitig ein grosses Geschenk, mich während der letzten 20 Jahre in Körperarbeit, Spiritualität und Medialität auszubilden.<br /><br />
In meiner Arbeit verbinde ich Therapie und feinstoffliche Energien.<br /><br />
Basierend auf diversen Ausbildungen, fundiertem Wissen und grossem Erfahrungsschatz, beruht meine Behandlungsweise auf verschiedenen Körpertherapien, die ich individuell auf deine Bedürfnisse anpasse. <br/><br/>
  Massagen<br/>
  Energiearbeit<br/>
  Metamorphose<br/>
  Körperkommunikation<br/><br/>
  Mehrere Jahre Erfahrung in<br/><br/>
  Medialität<br/>
  Trance<br/>
  Heilarbeit
          </p>
        </div>
      </div>
    </div>
  );



}

export default AboutMe;
