import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./components/price/price.css";

const root = document.getElementById('root');

render(
  <StrictMode>
    <App />
  </StrictMode>,
  root,
);
