import React, { useState, useRef, useEffect } from "react";


function TopNavigation() {
  const [navbar, setNavbar] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 300) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  }

  window.addEventListener('scroll', changeBackground);

  function scrollTo(id) {
    setDropdown(!dropdown);
    if(!window.location.pathname.split('/').pop() == ''){

      window.location.href='/#' + id;


      
    } else {
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    }
    
   
  }
  
  

  return (
    <div className={navbar ? 'top-bar' : 'transparent-bar'}>


      <div className="md:desktopTopBar hidden md:flex ">
        <ul className=" mr-8 absolute right-1 not-italic flex items-center font-medium">
        <button onClick={() => scrollTo('about')}><li className="icons-top">Über Mich</li></button>
        {//<button onClick={() => scrollTo('rezension')}><li className="icons-top">Rezensionen</li></button>
        }
        
        <button onClick={() => scrollTo('angebote')}><li className="icons-top">Angebote</li></button>
        <button onClick={() => scrollTo('contact')} className={navbar ? 'contactButtonBlack' : 'contactButtonWhite'}><li className="font-normal text-xl my-0 mx-3">Kontakt</li></button>
      </ul>
      <ul>
        <button onClick={() => scrollTo('welcome')} className=" m-5">
        
          {//<p className=" text-2xl text-green-700 font-bold">aletheisa</p>
          }<img src="/aletheias_logo.png" className="h-[6vh] md:h-[7vh] p-1"></img>
        </button>
        
      </ul>
      </div>




      <div className="md:hidden flex">

        

        <div className={dropdown ?  "mobilTopBaropen" : "mobilTopBarclosed"}>

          <ul className=" absolute right-1 not-italic font-medium flex items-center justify-center w-full h-full flex-col">

          <button onClick={() => scrollTo('about')}><li className="icons-top">Über Mich</li></button>
        {//<button onClick={() => scrollTo('rezension')}><li className="icons-top">Rezensionen</li></button>
        }
        <button onClick={() => scrollTo('angebote')}><li className="icons-top">Angebote</li></button>

            <button onClick={() => scrollTo('contact')} className={navbar ? 'contactButtonBlack' : 'contactButtonWhite'}><li className="font-normal text-xl my-0 mx-3">Kontakt</li></button>
          </ul>

        </div>
        

      <ul className=" flex items-center w-screen justify-between">
        
      <button onClick={() => scrollTo('welcome')} className=" m-5">
          
          {//<p className=" text-2xl text-green-700 font-bold">aletheisa</p>
          }<img src="/aletheias_logo.png" className="h-[6vh] md:h-[7vh] p-1"></img>
        </button>
        
        
          <button className="icons-top z-20 " onClick={() => {setDropdown(!dropdown)}} > <i class="fa-solid fa-bars not-italic mr-3"></i></button>
      </ul>
      </div>
      
    </div>
  );



}

export default TopNavigation;
