import React from "react";


function Welcome() {
  return (
    <div className="fullpage  w-full h-screen snap-center text-white flex flex-col justify-center items-center relative" id="welcome">
      <div className="flex flex-col justify-center items-center w-[90vw] md:w-[70vw] lg:w-[40vw]">
        <div className="lg:text-7xl md:text-6xl text-5xl font-extrabold flex flex-col justify-center items-center">
        <div className="">
          <h1 className=" inline">Körper,</h1>
          <h1 className=" inline text-[#236951]"> Seele</h1>
        </div>
        
        <h1>& Geist</h1>
      </div>

      <h3 className=" mt-10 font-semibold text-base md:text-lg text-center">
      Das Zusammenspiel von Körper, Seele & Geist ist ein Schlüssel des Wohlbefindens.

Sanfte Berührungen schaffen Kontakt, geben Gefühl von Sicherheit und Zugehörigkeit.

Die Kunst der Berührung ist auf physischer Ebene und geistiger Ebene heilend.

Eine sanfte Berührung gibt dem Menschen neue Kraft, besitzt die Macht unsere Seele aufleben zu lassen.
      </h3>

      <button onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })} className="hover:text-[#236951] hover:border-[#236951] mt-12 border-2 h-10 rounded-md w-32 p-0 flex items-center justify-center transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-100 duration-300"><li className="font-semibold text-xl md:text-2xl  no-underline list-none">Kontakt</li></button>



      </div>
      

      
      
      
    </div>
  );
}

export default Welcome;
