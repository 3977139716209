import React from "react";
import AboutMe from "./components/AboutMe/aboutme";
import ContactMe from "./components/ContactMe/contactme";
import Price from "./components/price/price";
import TopNavigation from "./components/TopNavigation";
import Welcome from "./components/welcome/welcome";

function App() {
  return (
    <div className="flex flex-col snap-mandatory snap-y">
      
      <TopNavigation/>
      <Welcome/>
      <AboutMe/>
      <Price/>
      <ContactMe/>
    </div>
  );
}

export default App;
