import React, { useState, useRef, useEffect } from "react";


function ContactMe() {
  
  

  return (
    <div className=" w-[100vw]   bg-white flex flex-col lg:flex-row   " id="contact">

      <div className=" w-[100vw] lg:w-[50vw] m-0 lg:h-[90vh] flex  justify-center ">
      
      <div>
        <div className=" text-4xl md:text-7xl font-semibold flex  ">
                  <h1>Kontaktiere </h1>
                  <h1 className="text-[#236951] ml-4"> Mich</h1>
                  
            </div>
            <div className=" w-14 md:w-16 h-1 bg-black mt-10 mb-[5vh]"></div>

            <div className="flex flex-col w-full h-[60vh] justify-evenly">

              <div className="flex items-center text-xl md:text-2xl font-semibold ">
              <i className="fa-solid fa-address-card text-3xl  md:text-4xl mr-5 "></i>
              <p>Franziska Rinderli</p>
            </div>
            <div className="flex items-center text-xl md:text-2xl font-semibold ">
              <i className="fa-solid fa-phone  text-3xl  md:text-4xl mr-5 "></i>
              <p>077 422 16 16</p>
            </div>
            <div className="flex items-center text-xl md:text-2xl font-semibold ">
              <i className="fa-solid fa-envelope  text-3xl  md:text-4xl mr-5"></i>
              <a className="text-[#236951] underline" href="mailto:franziska.rinderli@aletheias.ch">franziska.rinderli@aletheias.ch</a>
            </div>
            <div className="flex items-center text-xl md:text-2xl font-semibold ">
              <i className="fa-solid fa-location-dot  text-3xl  md:text-4xl mr-5 "></i>
              <p>
              Tannenweg 4 <br/>
              5600 Lenzburg
              </p>
            </div>

            </div>

            
      </div>


      </div>

      <div className="h-[90vh] w-[100vw] lg:w-[50vw] flex flex-col  items-center ">
        <div className="flex flex-col justify-center items-center w-[80vw] lg:w-[40vw]">
          
          <iframe className=" h-[50vh] w-full m-0 rounded-lg shadow-xl mb-[5vh]" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1802.7985943311492!2d8.166181243329728!3d47.389586987320904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47901606a06ffd41%3A0x57cd6dd1a14138a8!2sAletheias!5e0!3m2!1sde!2sch!4v1665319048833!5m2!1sde!2sch"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <p className=" text-xl md:text-2xl font-semibold">Hast du Fragen oder möchtest du mehr über mein Angebot wissen? <br/> Ich freue mich auf einen Anruf oder Nachricht.</p>
          
          </div>
          
      </div>


    </div>
  );



}

export default ContactMe;
